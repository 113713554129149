import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Information from "./pages/Information";
import Team from "./pages/Team";
import Jury from "./pages/Jury";
import Partners from "./pages/Partners";
import "./styles/global.css";
import RegistrationRu from "./pages/RegistrationRU";
import RegistrationForeign from "./pages/RegistrationForeign";
import Registration from "./pages/Registration";
import Legend from "./pages/Legend";
import Results from "./pages/Results";

function App() {
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const currentHost = window.location.hostname;
    setIsSubdomain(currentHost === "register.ctfcup.ru");

    const checkDate = () => {
      const currentDate = new Date();
      const targetDate = new Date('2024-10-26T09:00:00');
      setShowLegend(currentDate >= targetDate);
    };

    checkDate();
    const timer = setInterval(checkDate, 60000); // Check every minute

    return () => clearInterval(timer);
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          {isSubdomain ? (
            <Route path="/" element={<Registration />} />
          ) : (
            <Route path="/" element={<Home />} />
          )}
          <Route path="/information" element={<Information />} />
          <Route path="/team" element={<Team />} />
          <Route path="/jury" element={<Jury />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/registration-ru" element={<RegistrationRu />} />
          <Route path="/registration-foreign" element={<RegistrationForeign />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/results" element={<Results />} />
          {showLegend && <Route path="/legend" element={<Legend />} />}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;