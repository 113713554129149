import React, { useState, useEffect } from 'react';
import '../styles/Registration.css';
import { useNavigate } from "react-router-dom";

function RegistrationRu() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to /results on component mount
    navigate("/results");
  }, [navigate]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Имитация загрузки в течение 2 секунд

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="registration-ru">
      <h1>Регистрация</h1>
      <div className="registration-form-container">
        {isLoading ? (
          <div className="loader">
            <div className="spinner"></div>
          </div>
        ) : (
          <iframe 
            style={{border: 'none', width: '100%'}} 
            src="https://forms.ctfcup.ru/forms/kubok-ctf-rossii-2024-exsmn4"
            title="Registration Form"
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default RegistrationRu;