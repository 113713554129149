import React from 'react';
import legendPdf from '../assets/legend.pdf';
import pdf from '../assets/pdf.png';
import '../styles/Legend.css';

function Legend() {
  return (
    <div className="legend">
      <div className="legend__container">
        <h1 className="legend__title">Легенда</h1>
        <div className='legend__description'>
        Пришло время ознакомиться с первой частью легенды!<br/>
И не забывайте про правила чтения: порядок идёт справа налево.
        </div>
        <div className="legend__pdf-wrapper">
          <object
            data={legendPdf}
            type="application/pdf"
            width="100%"
            height="100%"
            className="legend__pdf"
          >
            <p className="legend__fallback">
            <img src={pdf} /> <br />
                Похоже, твой браузер не может открыть PDF формат. <br /> Попробуй скачать легенду по <a href={legendPdf} className="legend__download-link">ссылке</a>.
            </p>
          </object>
        </div>
      </div>
    </div>
  );
}

export default Legend;