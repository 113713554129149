import React, { useState } from "react";
import "../styles/Information.css";

const faqData = [
  {
    question: "Могу ли я принять участие в Кубке, если меня отчислили?",
    answer: "Да, в этом году в Смешанном зачете могут принять участие любые люди не старше 27 лет (включительно)"
  },
  {
    question: "Какой зачет нам выбрать, если мы студенты, но у нас есть школьник в составе?",
    answer: "В любой непонятной ситуации выбирайте Смешанный зачет. Школьный только для школьников, а Академический — для студентов одного вуза (или колледжа)"
  },
  {
    question: "Могут ли пройти на финал иностранные команды",
    answer: "Теоретически — да. А на практике каждая заявка иностранной команды будет рассматриваться в индивидуальном порядке Оргкомитетом"
  },
  {
    question: "Приз — один на три зачета?",
    answer: "А вот и нет — победитель каждого из трех зачетов получит и денежный приз, и свой экземпляр кубка ;)"
  },
  {
    question: "Когда будут известны результаты отборочного тура?",
    answer: "Мы постараемся опубликовать результаты как можно скорее — но обычно нам нужно 2-3 дня, чтобы точно проверить результаты: за это время убедимся, что:"+
  "<br /><br /><li>никто из команд не спутал зачеты,</li>"+
  "<li>предоставил всю информацию в регистрации,</li>"+
  "<li>не жульничал во время этапа.</li>"
  },
  {
    question: "Сколько этапов ждут школьников?",
    answer: "В Школьном зачете всего два этапа: общий отборочный этап, а также финал в декабре (смотри расписание на главной странице)"
  }
];

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'active' : ''}`}>
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <span>{question}</span>
        <div className="faq-toggle">
          <div className="faq-toggle-vertical"></div>
          <div className="faq-toggle-horizontal"></div>
        </div>
      </div>
      {isOpen && (
        <div 
          className="faq-answer" 
          dangerouslySetInnerHTML={{ __html: answer.replace(/\n/g, '<br>') }}
        />
      )}
    </div>
  );
}

function Information() {
  return (
    <section className="information">
      <h2 className="information__title">ПРАВИЛА</h2>
      <ul className="information__rules">
        <li className="information__rule">
          <div className="information__rule-marker">1</div>
          <p className="information__rule-text">
            Команды могут принять участие в одном из трех зачетов: Школьном,
            Академическом или Смешанном
          </p>
        </li>
        <li className="information__rule">
          <div className="information__rule-marker">2</div>
          <p className="information__rule-text">
            Возраст участников — <br />от 14 до 27 лет включительно
          </p>
        </li>
        <li className="information__rule">
          <div className="information__rule-marker">3</div>
          <p className="information__rule-text">
            По итогу отборочного тура в Полуфинал проходят 30 команд, по 10
            команд в каждом зачете
          </p>
        </li>
        <li className="information__rule">
          <div className="information__rule-marker">4</div>
          <p className="information__rule-text">
            В полуфинале от одного учебного заведения — не более 1 команды
          </p>
        </li>
      </ul>
      <h2 className="information__faq-title">FAQ</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
      <div className="information__bottom"></div>
    </section>
  );
}

export default Information;