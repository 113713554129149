import React from 'react';
import '../styles/Team.css';

import image1 from '../assets/committee/2025.png';
import image2 from '../assets/committee/budnikov.png';
import image3 from '../assets/committee/khasin.png';
import image4 from '../assets/committee/arbuzov.png';
import image5 from '../assets/committee/barkhatov.png';
import image6 from '../assets/committee/shevtsov.png';
import image7 from '../assets/committee/masalovich.png';
import image8 from '../assets/jury/image-7.png';
import image9 from '../assets/committee/izbaenkov.png';
import image10 from '../assets/committee/minin.png';
import image11 from '../assets/committee/vasilyeva.png';
import image12 from '../assets/committee/biryukov.png';
import katusov from '../assets/committee/katusov.png';
import komarova from '../assets/committee/komarova.png';

import molchanov from '../assets/team/molchanov.png';
import nikolaev from '../assets/team/nikolaev.png';
import zeunov from '../assets/team/zeunov.png';
import shpilev from '../assets/team/shpilev.png';
import smirnov from '../assets/team/smirnov.png';
import novikov from '../assets/team/novikov.png';
import pokrovsky from '../assets/team/pokrovsky.png';
import anri from '../assets/team/anri.png';
import loginova from '../assets/team/loginova.png';
import karelin from '../assets/team/karelin.png';
import mikheev from '../assets/team/mikheev.png';
import nikitin from '../assets/team/nikitin.png';
import zelentsova from '../assets/team/zelentsova.png';
import lepilova from '../assets/team/lepilova.png';
import fedotov from '../assets/team/fedotov.png';
import emelyanov from '../assets/team/emelyanov.png';
import sokolovskiy from '../assets/team/sokolovskiy.png';
import nalivaiko from '../assets/team/nalivaiko.png';
import antonova from '../assets/team/antonova.png';
import sokolov from '../assets/team/sokolov.png';
import gubareva from '../assets/team/gubareva.png';
import terentyeva from '../assets/team/terentyeva.png';
import rubleva from '../assets/team/rubleva.png';

const organizingCommittee = [
  {
    name: 'ПЯРИН ВИКТОР АНАТОЛЬЕВИЧ',
    position: 'Председатель оргкомитета — действительный член АИН, член-корреспондент РАЕН и Академии Криптографии, лауреат Государственной премии, к.ф-м.н',
    image: image1
  },
  {
    name: 'БУДНИКОВ АЛЕКСАНДР',
    position: 'Председатель жюри, управляющий директор по информационной безопасности, ПАО АФК «Система»',
    image: image2
  },
  {
    name: 'ХАСИН ЕВГЕНИЙ',
    position: 'Заместитель директора Департамента обеспечения кибербезопасности Минцифры России',
    image: image3
  },
  {
    name: 'АРБУЗОВ АНДРЕЙ',
    position: 'Исполнительный директор ФСП',
    image: image4
  },
  {
    name: 'БАРХАТОВ ДМИТРИЙ',
    position: 'Председатель Координационного совета, Всероссийское общественное движение наставников детей и молодежи «Наставники России»',
    image: image5
  },
  {
    name: 'ШЕВЦОВ ДМИТРИЙ',
    position: 'Начальник управления ФСТЭК России',
    image: image6
  },
  {
    name: 'МАСАЛОВИЧ АНДРЕЙ',
    position: 'Президент Консорциума «Инфорус», к.ф-м.н',
    image: image7
  },
  {
    name: 'ВОЙНОВ ЮРИЙ',
    position: 'Начальник Департамента информационных технологий, связи и защиты информации, МВД России',
    image: image8
  },
  {
    name: 'ИЗБАЕНКОВ АРТЕМ',
    position: 'Директор облачной платформы Solar Space ГК «Солар»',
    image: image9
  },
  {
    name: 'МИНИН ВИКТОР',
    position: 'Председатель правления АРСИБ',
    image: image10
  },
  {
    name: 'ВАСИЛЬЕВА ВЛАДИСЛАВА',
    position: 'Заместитель директора направления «Безопасная Открытая Инфраструктура» АНО «Цифровая экономика»',
    image: image11
  },
  {
    name: 'БИРЮКОВ ИГОРЬ',
    position: 'Руководитель Киберхаба Фонда «Сколково»',
    image: image12
  },
  {
    name: 'КАТУСОВ АЛЕКСЕЙ',
    position: 'Начальник Управления информационной безопасности ДИТ города Москвы',
    image: katusov,
  },
  {
    name: 'НИКА КОМАРОВА',
    position: 'Консультант по стратегическим коммуникациям',
    image: komarova,
  },
];

const projectTeam = [
  {
    name: 'ЛОГИНОВА ДАРЬЯ',
    position: 'Куратор проекта, C4T BuT S4D',
    image: loginova
  },
  {
    name: 'СМИРНОВ МАКСИМ',
    position: 'Технический директор',
    image: smirnov
  },
  {
    name: 'ЗЕЛЕНЦОВА ОЛЬГА',
    position: 'Организатор',
    image: zelentsova
  },
  {
    name: 'ЛЕПИЛОВА ВИКТОРИЯ',
    position: 'Организатор',
    image: lepilova
  },
  {
    name: 'МОЛЧАНОВ ДМИТРИЙ',
    position: 'Организатор',
    image: molchanov
  },
  {
    name: 'АНРИ ГАЛИЛЕЯ',
    position: 'Мангака',
    image: anri
  },
  {
    name: 'КАРЕЛИН ЕВГЕНИЙ',
    position: 'Редактор',
    image: karelin
  },
  {
    name: 'ШПИЛЕВ РОМАН',
    position: '3D художник',
    image: shpilev
  },
  {
    name: 'НИКОЛАЕВ ПАВЕЛ',
    position: 'Технический специалист',
    image: nikolaev
  },
  {
    name: 'ЕМЕЛЬЯНОВ ЕВГЕНИЙ',
    position: 'Технический специалист',
    image: emelyanov
  },
  {
    name: 'ЗЕУНОВ АНТОН',
    position: 'Frontend-разработчик',
    image: zeunov
  },
  {
    name: 'НИКИТИН РОМАН',
    position: 'DevOps, C4T BuT S4D',
    image: nikitin
  },
  {
    name: 'НОВИКОВ ИВАН',
    position: 'Разработчик, C4T BuT S4D',
    image: novikov
  },
  {
    name: 'МИХЕЕВ АРТЕМ',
    position: 'Разработчик, C4T BuT S4D',
    image: mikheev
  },
  {
    name: 'ПОКРОВСКИЙ НИКИТА',
    position: 'Разработчик, C4T BuT S4D',
    image: pokrovsky
  },
  {
    name: 'ФЕДОТОВ ИВАН',
    position: 'Разработчик, C4T BuT S4D',
    image: fedotov
  },
  {
    name: 'СОКОЛОВСКИЙ АЛЕКСЕЙ',
    position: 'Разработчик, C4T BuT S4D',
    image: sokolovskiy
  },
  {
    name: 'НАЛИВАЙКО ЛЮБОВЬ',
    position: 'Ведущая трансляции',
    image: nalivaiko
  },
  {
    name: 'АНТОНОВА ЕЛИЗАВЕТА',
    position: 'Корреспондент трансляции',
    image: antonova
  },
  {
    name: 'СОКОЛОВ КОНСТАНТИН',
    position: 'Backend-разработчик',
    image: sokolov
  },
  {
    name: 'ГУБАРЕВА ЯНА',
    position: 'Графический дизайнер',
    image: gubareva
  },
  {
    name: 'ТЕРЕНТЬЕВА МАРИЯ',
    position: 'Графический дизайнер',
    image: terentyeva
  },
  {
    name: 'РУБЛЕВА МАРГАРИТА',
    position: 'Старший графический дизайнер',
    image: rubleva
  },
];

function Team() {
  return (
    <div className="team">
      <section className="team__section">
        <h1 className="team__title">КОМАНДА. <span className="team__title--committee">ОРГКОМИТЕТ</span></h1>
        <div className="team__members">
          {organizingCommittee.map((member, index) => (
            <div key={index} className="team__member">
              <img src={member.image} alt={member.name} className="team__member-image" />
              <div className="team__member-info">
                <h2 className="team__member-name">{member.name}</h2>
                <p className="team__member-position">{member.position}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="team__section team__section--project">
        <h1 className="team__title">КОМАНДА. ПРОЕКТНАЯ</h1>
        <div className="team__members">
          {projectTeam.map((member, index) => (
            <div key={index} className="team__member">
              <img src={member.image} alt={member.name} className="team__member-image" />
              <div className="team__member-info">
                <h2 className="team__member-name">{member.name}</h2>
                <p className="team__member-position">{member.position}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Team;